@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu-inline-collapsed {
  width: 48px;
}

.ant-layout-header {
  height: 48px;
  line-height: 48px;
  padding: 0 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 21, 41, 8%);
}

.card-table {
  border-color: '#000000';
  table-layout: fixed;
}

.card-table table {
  width: 100%;
}

.card-table .ant-card-body {
  padding: 0;
}

.card-table th {
  width: 150px;
  text-align: left;
}

.card-table td,
.card-table th {
  padding: 8px 12px;
  border-top: 1px solid #f0f0f0;
  overflow: scroll;
  text-overflow: clip;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card-table td::-webkit-scrollbar,
.card-table th::-webkit-scrollbar {
  display: none;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 6px;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
}

.ant-card-bordered {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.ant-timeline-item-tail {
  border-left: 2px solid #dbdbdb;
}

.ant-timeline-item-head-custom {
  border-radius: 50%;
  font-size: 16px;
  background: transparent;
}
